<template>
  <div class="teacher_daping_main">
    <fs :fullscreen.sync="fullscreen" class="daping">
      <div class="left">
        <div :class="['item', { active: currentIndex == k }]" :style="'height:' + 100 / courseTimes.length + '%'" v-for="(v, k) in courseTimes" :key="k" @click="itemClick(v)">
          第{{ sections[v.sort] }}节课
        </div>
      </div>
      <div class="right" v-if="courseTimes.length > 0">
        <div class="title" @click="fullscreen = !fullscreen">
          {{ userInfo.tnName }}
        </div>
        <div class="tips">
          <div class="tips_left">
            <img src="/aaw/img/jieci.png" width="30" height="18" alt="" />
            <span style="margin: 0 16px 0 4px">{{
              "第" + sections[courseTimes[currentIndex].sort] + "节课"
            }}</span>
            <span>{{ courseTimes[currentIndex].startTime }}</span>
            一一
            <span>{{ courseTimes[currentIndex].endTime }}</span>
          </div>
          <div class="shili">
            <div class="item_img" v-for="(v, k) in shili" :key="k">
              <img :src="v.img" width="60" height="24" alt="" />
              {{ v.title }}
            </div>
          </div>
        </div>
        <img src="/aaw/img/xian.png" width="100%" height="8px" alt="" />
        <div class="all_class">
          <div :class="'class_item ' + getClass(v.time, v)" v-for="(v, k) in allClass" :key="k" :style="'height: calc(' + currentHeight + ' - 6px)'">
            <div class="a">
              <img src="/aaw/img/class1.png" width="12" height="12" alt="" />
              <div>{{ v.gradeName + v.className }}</div>
            </div>
            <div class="a">
              <img src="/aaw/img/class.png" width="12" height="12" alt="" />
              <div>{{ v.courseName }}课</div>
            </div>
            <div class="a">
              <img src="/aaw/img/name.png" width="12" height="12" alt="" />
              <div>{{ v.teachName }}</div>
            </div>
          </div>
        </div>
      </div>
    </fs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueFullscreen from "vue-fullscreen";
import Vue from "vue";
Vue.use(VueFullscreen, { name: "fs" });
// import { teacherRecordList } from "@/http/analysis";
import { getCourseTime, getAttendanceStat } from "@/http/daping";
// import { getSchoolTerm } from '@/http/leaveSchool'

export default {
  data() {
    return {
      courseTimes: [],
      sections: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      fullscreen: false,
      currentTime: new Date().getTime(),
      allClass: [],
      initCurrent: -1,
      shili: [
        {
          title: "未开始",
          img: "/aaw/img/c1.png",
        },
        {
          title: "已到",
          img: "/aaw/img/c2.png",
        },
        {
          title: "迟到",
          img: "/aaw/img/c3.png",
        },
        {
          title: "未到",
          img: "/aaw/img/c4.png",
        },
      ],
      setTimeout: null,
      interval: null,
      getAttendanceStatInt: null,
    };
  },
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(["userInfo"]),
    /* eslint-disable */
    currentIndex: {
      get() {
        let index = 0;
        for (var i = 0; i <= this.courseTimes.length - 1; i++) {
          if (this.currentTime <= this.courseTimes[i].endTimeT) {
            index = i;
            break;
          }
        }
        if (this.initCurrent < 0 && this.courseTimes.length > 0) {
          this.initCurrent = index;
          this.itemClick(this.courseTimes[index]);
        }
        if (this.courseTimes.length > 0 && this.initCurrent == index) {
          this.setInterval(
            this.courseTimes[index].endTimeT - new Date().getTime()
          );
        }
        return index;
      },
      set(n) {
        this.currentTime = this.courseTimes[n].endTimeT;
      },
    },
    /* eslint-disable */
    currentHeight() {
      let fen =
        this.allClass.length % 10 > 0
          ? parseInt(this.allClass.length / 10) + 1
          : parseInt(this.allClass.length / 10);
      return 100 / fen + "%";
    },
  },
  created() {
    this.getCourseTime();
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
    clearTimeout(this.interval);
    clearInterval(this.getAttendanceStatInt);
  },
  methods: {
    setInterval(time) {
      console.log("-=-=-=-", time);
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (time > 0) {
        this.interval = setTimeout(() => {
          clearInterval(this.interval);
          let time = new Date().getTime();
          let index = 0;
          for (var i = 0; i <= this.courseTimes.length - 1; i++) {
            if (time <= this.courseTimes[i].endTimeT) {
              index = i;
              break;
            }
          }
          this.initCurrent = index;
          this.currentTime = time;
          this.itemClick(this.courseTimes[this.initCurrent]);
        }, time + 1);
      }
    },
    getClass(time, item) {
      console.log(item);

      switch (item.status) {
        case 1:
          return "class_item1"; // 绿色 准时
        case 2:
          return "class_item3"; // 陈晓明（黄色） 迟到
        case 3:
          return "class_item4"; // 请假 红色
        case 4:
          return "class_item4"; // 缺勤 红色
        default:
          return "class_item2"; // 灰色
      }

      // if (time) {
      //   if (time < this.courseTimes[this.currentIndex].startTimeT) {
      //     return "class_item1";
      //   } else if (
      //     time >= this.courseTimes[this.currentIndex].startTimeT &&
      //     time <= this.courseTimes[this.currentIndex].endTimeT
      //   ) {
      //     return "class_item3";
      //   } else if (time > this.courseTimes[this.currentIndex].endTimeT) {
      //     return "class_item4";
      //   }
      // } else {
      //   if (
      //     new Date().getTime() < this.courseTimes[this.currentIndex].startTimeT
      //   ) {
      //     return "class_item2";
      //   } else if (
      //     new Date().getTime() >=
      //       this.courseTimes[this.currentIndex].startTimeT &&
      //     new Date().getTime() <= this.courseTimes[this.currentIndex].endTimeT
      //   ) {
      //     return "class_item4";
      //   } else if (
      //     new Date().getTime() > this.courseTimes[this.currentIndex].endTimeT
      //   ) {
      //     return "class_item4";
      //   }
      // }
    },
    itemClick(v) {
      this.currentTime = v.endTimeT;
      this.getAttendanceStat();
      if (!this.getAttendanceStatInt) {
        this.getAttendanceStatInt = setInterval(this.getAttendanceStat, 50000);
      }
    },
    getAttendanceStat() {
      let params = {
        schoolId: this.userInfo.tnId,
        sort: this.courseTimes[this.currentIndex].sort,
      };
      getAttendanceStat(params).then((res) => {
        this.allClass = res.data;
      });
    },
    getCourseTime() {
      let params = {
        schoolId: this.userInfo.tnId,
      };
      getCourseTime(params).then((res) => {
        let courseTimes = [];
        res.data.forEach((item) => {
          if (item.courseTimes.length > courseTimes.length) {
            courseTimes = item.courseTimes;
          }
        });
        let currentDate = this.$cftY().split(" ")[0].replaceAll("/", "-");
        courseTimes.forEach((item) => {
          item.startTimeT = new Date(
            currentDate + " " + item.startTime
          ).getTime();
          item.endTimeT = new Date(currentDate + " " + item.endTime).getTime();
        });
        this.courseTimes = courseTimes;
      });
    },
  },
  watch: {
    currentIndex(n) {
      clearTimeout(this.setTimeout);
      if (this.initCurrent != n) {
        this.setTimeout = setTimeout(() => {
          this.currentIndex = JSON.parse(JSON.stringify(this.initCurrent));
          this.itemClick(this.courseTimes[this.initCurrent]);
        }, 30000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_daping_main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  .daping {
    background: #ffffff;
    display: flex;
    height: 100%;
    .left {
      width: 170px;
      height: 100%;
      .item {
        background: url(/aaw/img/selete.png) no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid #ffffff;
        font-size: 27px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        position: relative;
        width: 190px;
        height: 100%;
        background: url(/aaw/img/selected.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .right {
      width: calc(100% - 170px);
      padding: 12px 36px;
      .all_class {
        height: calc(100% - 140px);
        overflow: hidden;
        margin-right: -6px;
        margin-bottom: -6px;
        margin-top: 0;
        font-size: 12px;
        .class_item {
          width: calc(10% - 6px);
          max-height: 16.7%;
          color: #fff;
          overflow: hidden;
          padding: 4px 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          float: left;
          margin-right: 6px;
          margin-bottom: 6px;
          .a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
              width: 80%;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .class_item1 {
          background: url(/aaw/img/b1.png) no-repeat;
          background-size: 100% 100%;
        }
        .class_item2 {
          background: url(/aaw/img/b2.png) no-repeat;
          background-size: 100% 100%;
        }
        .class_item3 {
          background: url(/aaw/img/b3.png) no-repeat;
          background-size: 100% 100%;
        }
        .class_item4 {
          background: url(/aaw/img/b4.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .title {
        font-size: 28px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        color: #323232;
        text-align: center;
        margin-bottom: 40px;
      }
      .tips {
        font-weight: 600;
        color: #323232;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 40px;
        .tips_left {
          width: 40%;
        }
        .shili {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 60%;
          .item_img {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              margin-right: 12px;
            }
          }
        }
      }
      .iten {
        width: calc(10%);
      }
    }
  }
}
</style>